import styled, { css } from 'styled-components';
import { lessThan } from '@utils/mediaQuery';

const sideWidth = 480;

export const sideGutter = 80;

export const sideBreakpoint = sideWidth * 2 + sideGutter;

export const Container = styled.div`
  ${({ theme, spaced }) =>
    theme.layouts.sidebar(
      `${sideWidth}px`,
      'left',
      '50%',
      spaced ? `${sideGutter}px` : 0
    )}

  > * {
    min-width: 50%;
  }

  ${({ spaced }) =>
    spaced &&
    css`
      > * {
        /* Subtract 0.1px to avoid weird effect with calc and rem on Firefox */
        min-width: calc(50% - ${sideGutter}px - 0.1px);
      }

      ${lessThan(sideBreakpoint)} {
        margin-top: -${15 / 16}rem;
        margin-bottom: -${15 / 16}rem;

        > * {
          margin-top: ${15 / 16}rem;
          margin-bottom: ${15 / 16}rem;
        }
      }
    `}
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;
