// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import { Wrapper, Container } from './SideBarLayout.styles';

const SideBarLayout = ({ tag, spaced, children, ...rest }) => {
  const childrenCount = React.Children.count(children);

  if (childrenCount !== 2) {
    return new Error(
      'SideBarLayout component should have exactly two child elements of its own`'
    );
  }

  return (
    <Wrapper {...rest}>
      <Container as={tag} spaced={spaced}>
        {children}
      </Container>
    </Wrapper>
  );
};

SideBarLayout.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  spaced: PropTypes.bool,
};
SideBarLayout.defaultProps = {
  tag: null,
  spaced: false,
};

export default SideBarLayout;
