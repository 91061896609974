/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import { graphql, Link } from 'gatsby';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

// utils
import generateLangLinks from '@utils/generateLangLinks';
import generatePath from '@utils/generatePath';
import {
  greaterThan,
  lessThanCondition,
  greaterThanCondition,
} from '@utils/mediaQuery';

// images
import IconArrow from '@images/IconArrow';

// components
import Layout from '@components/Layout';
import { useMedia } from 'react-use';
import SEO from '@components/SEO';
import SideBarLayout from '@components/SideBarLayout';
import BlockContent from '@components/BlockContent';
import Button from '@components/Button';
import Gallery from '@components/Carousel';

// styles
import { h4Style } from '@styles/global';
import { TextWrapper as ButtonTextWrapper } from '@components/Button/Button.styles';
import {
  breakpoints,
  smallerTextStyle,
  DetailsNavigationContainer,
  DetailsNavigationWrapper,
  DetailsContent,
  NavigationContent,
  GalleryContainer,
  GalleryWrapper,
  List,
  ListItem,
  Title,
} from './ProjectTemplate.styles';

const ProjectTemplate = ({ data, pageContext }) => {
  const {
    project: {
      seo,
      localization: { translations = [] },
      _type: type,
      ...project
    },
    projects: { nodes: projects },
  } = data;

  const { seoTitle = undefined, metaDescription = undefined } = seo || {};

  const isMobile = useMedia(lessThanCondition(breakpoints[2]));

  const usedProducts = project.usedProducts.map((product) => {
    const {
      _type: productType,
      slug: { current: slug },
    } = product;

    const path = generatePath(slug, pageContext.langKey, productType);

    return {
      to: path,
      ...product,
    };
  });

  const langLinks = generateLangLinks(translations);

  const index = projects.findIndex(({ id }) => id === pageContext.id);

  const nextIndex = index + 1 === projects.length ? 0 : index + 1;
  const prevIndex = index - 1 < 0 ? projects.length - 1 : index - 1;

  const nextURL = generatePath(
    projects[nextIndex].slug.current,
    pageContext.langKey,
    type
  );
  const prevURL = generatePath(
    projects[prevIndex].slug.current,
    pageContext.langKey,
    type
  );

  const projectsURL = generatePath(null, pageContext.langKey, type);

  const pictures = project.pictures.map((picture) => ({
    id: picture.asset.id,
    key: picture._key,
    alt: picture.alt,
    caption: picture.caption,
    sourcePicture: [
      {
        ...picture.asset.slidePictureDesktop,
        media: greaterThanCondition(breakpoints[2]),
      },
      { ...picture.asset.slidePictureMobile, sizes: '100vw' },
    ],
    sourceThumbnail: [
      {
        ...picture.asset.slideThumbnailDesktop,
        media: greaterThanCondition(breakpoints[2]),
      },
      { ...picture.asset.slideThumbnailMobile, sizes: '100vw' },
    ],
  }));

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang={pageContext.langKey}
        title={seoTitle}
        description={metaDescription}
        langLinks={langLinks}
      />

      <SideBarLayout
        css={`
          ${greaterThan(breakpoints[2])} {
            overflow: inherit;
          }
        `}
      >
        <DetailsNavigationContainer>
          <DetailsNavigationWrapper>
            <DetailsContent>
              {!isMobile && <Title>{project.title}</Title>}

              <div>
                <p
                  css={`
                    margin-bottom: 0;
                  `}
                >{`${project.city}, ${project.state}`}</p>

                {project.promoter && (
                  <p
                    css={`
                      margin-top: 10px;
                    `}
                  >
                    {project.promoter.join(' / ')}
                  </p>
                )}
              </div>

              <div css={smallerTextStyle}>
                <BlockContent blocks={project._rawShortDescription} />

                <p>
                  <strong>
                    <FormattedMessage
                      defaultMessage='Catégorie de projet'
                      description='Project category text in the project template'
                    />
                  </strong>
                  <span>: </span>
                  {project.productCategories && (
                    <span>{project.productCategories.join(' / ')}</span>
                  )}
                </p>
              </div>

              <div css={smallerTextStyle}>
                <h2
                  css={`
                    ${h4Style};

                    ${greaterThan(breakpoints[2])} {
                      font-weight: ${({ theme }) => theme.fontWeights.medium};
                    }
                  `}
                >
                  <FormattedMessage
                    defaultMessage='Produits installés et services offerts:'
                    description='Installed products and offered services text in the project template'
                  />
                </h2>

                <List>
                  {usedProducts.map((product) => (
                    <ListItem key={product.id}>
                      <Link to={product.to}>{product.title}</Link>
                    </ListItem>
                  ))}
                </List>
              </div>
            </DetailsContent>

            <NavigationContent>
              <Button
                outlined
                primary
                to={prevURL}
                tag='link'
                iconFirst
                renderIcon={
                  <IconArrow style={{ transform: 'rotate(180deg)' }} />
                }
                css={`
                  ${ButtonTextWrapper} {
                    text-align: left;
                  }
                `}
              >
                <FormattedMessage
                  defaultMessage='Précédent'
                  description='CTA button to go to the previous project page in the projects template'
                />
              </Button>

              <Button
                outlined
                primary
                to={nextURL}
                tag='link'
                renderIcon={<IconArrow />}
              >
                <FormattedMessage
                  defaultMessage='Suivant'
                  description='CTA button to go to the next project page in the projects template'
                />
              </Button>

              <Button
                outlined
                primary
                to={projectsURL}
                tag='link'
                iconFirst
                renderIcon={
                  <IconArrow style={{ transform: 'rotate(180deg)' }} />
                }
                css={`
                  ${ButtonTextWrapper} {
                    text-align: left;
                  }
                `}
              >
                <FormattedMessage
                  defaultMessage='Nos réalisations'
                  description='CTA button to go back to the projects page in the projects template'
                />
              </Button>
            </NavigationContent>
          </DetailsNavigationWrapper>
        </DetailsNavigationContainer>

        <GalleryContainer
          css={`
            min-width: calc(50% + 40px);
          `}
        >
          <GalleryWrapper>
            {isMobile && (
              <Title
                css={`
                  padding-left: 24px;

                  color: ${({ theme }) => theme.colors.secondary};
                `}
              >
                {project.title}
              </Title>
            )}

            {pictures.length > 0 && (
              <Gallery slides={pictures} aria-label={project.title} />
            )}
          </GalleryWrapper>
        </GalleryContainer>
      </SideBarLayout>
    </Layout>
  );
};

export default ProjectTemplate;

ProjectTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    langKey: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query ProjectTemplateQuery($id: String!, $langKey: String!) {
    project: sanityProject(id: { eq: $id }) {
      _type
      title
      city
      state
      promoter
      _rawShortDescription(resolveReferences: { maxDepth: 10 })
      pictures {
        _key
        alt
        asset {
          id
          slidePictureDesktop: fluid(maxWidth: 664, maxHeight: 678) {
            ...GatsbySanityImageFluid
          }
          slidePictureMobile: fluid(maxWidth: 960, maxHeight: 834) {
            ...GatsbySanityImageFluid
          }
          slideThumbnailDesktop: fluid(maxWidth: 202, maxHeight: 140) {
            ...GatsbySanityImageFluid
          }
          slideThumbnailMobile: fluid(maxWidth: 342, maxHeight: 236) {
            ...GatsbySanityImageFluid
          }
        }
        caption
      }
      productCategories
      usedProducts {
        _type
        id
        title
        slug {
          current
        }
      }
      seo {
        metaDescription: meta_description
        seoTitle: seo_title
      }
      localization {
        ...alternativeLangHref
      }
    }
    projects: allSanityProject(
      filter: { localization: { locale: { eq: $langKey } } }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
      }
    }
  }
`;
