module.exports = (slug, locale = 'fr', type = undefined) => {
  let directory = '';

  if (type === 'product' || type === 'productCategory') {
    directory = locale === 'fr' ? '/produits' : '/en/products';
  }

  if (type === 'project') {
    directory = locale === 'fr' ? '/realisations' : '/en/projects';
  }

  return slug ? `${directory}/${slug}` : directory;
};
