// vendors
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// utils
import { lessThan, greaterThan } from '@utils/mediaQuery';

// styles
import { h1Style } from '@styles/global';

export const space = rem(60, 22);

export const breakpoints = [480, 640, 960];

export const smallerTextStyle = css`
  font-size: 16px;
  line-height: ${26 / 16};
`;

export const DetailsNavigationContainer = styled.div`
  min-width: calc(50% - 40px);
  padding-left: max(calc(50% - 1408px / 2), 24px);
`;

export const DetailsNavigationWrapper = styled.div`
  margin: ${rem(60, 16)} 0;
  padding-right: 24px;

  ${greaterThan(breakpoints[2])} {
    box-sizing: content-box;

    max-width: 540px;

    margin: ${rem(128, 22)} 0;
  }
`;

export const DetailsContent = styled.div`
  ${({ theme }) => theme.layouts.stack(space)};
`;

export const NavigationContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${space};

  ${({ theme }) => theme.layouts.stack(space)};

  ${greaterThan(breakpoints[1])} {
    flex-direction: row;
    flex-wrap: wrap;

    margin-right: calc(38px / 2 * -1);
    margin-left: calc(38px / 2 * -1);

    > * {
      flex-basis: 25%;
      flex-grow: 1;

      margin: calc(38px / 2);
    }

    > :last-child {
      flex-basis: 100%;

      margin-top: calc(${space} - 38px / 2);
    }
  }
`;

export const Title = styled.h1`
  ${h1Style}

  color: ${({ theme }) => theme.colors.primary};

  ${greaterThan(breakpoints[0])} {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const List = styled.ul`
  margin: 0;
  margin-top: ${rem(22, 22)};
  padding: 0;

  list-style: none;

  ${({ theme }) => theme.layouts.stack(rem(22, 22))}
`;

export const ListItem = styled.li`
  > * {
    color: ${({ theme }) => theme.colors.charcoal};
  }
`;

export const GalleryContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.charcoal};

  ${greaterThan(breakpoints[2])} {
    padding-right: max(calc(50% - 1408px / 2), 24px);
  }

  ${lessThan(breakpoints[2])} {
    order: -1;
  }
`;

export const GalleryWrapper = styled.div`
  margin-bottom: ${rem(60, 16)};

  ${greaterThan(breakpoints[2])} {
    position: sticky;
    top: 0;

    margin-top: ${rem(128, 22)};
    padding-left: 80px;
  }
`;
