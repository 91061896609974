import { graphql } from 'gatsby';
import generatePath from './generatePath';

export default (translations) =>
  translations.map((translation) => {
    const {
      type,
      localization: { locale: langKey },
      slug: { current: slug },
    } = translation;

    const href = generatePath(slug, langKey, type);

    return { langKey, href };
  });

export const query = graphql`
  fragment alternativeLangHref on SanityLocalization {
    translations {
      ... on SanityProject {
        type: _type
        localization {
          locale
        }
        slug {
          current
        }
      }

      ... on SanityProduct {
        type: _type
        localization {
          locale
        }
        slug {
          current
        }
      }

      ... on SanityProductCategory {
        type: _type
        localization {
          locale
        }
        slug {
          current
        }
      }
    }
  }
`;
